import React, { useEffect, useRef } from 'react';
import { arrayOf, func, string, object } from 'prop-types';
import { Heading } from '@nutkit/component-text';
import { DraftsList } from '@nm-pot/overview';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { useGetCustomerDependantPersonalInformation } from '@nm-customer/common/hooks';

const TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.draftsSection';

const DraftPots = ({ drafts, userUuid, updateDrafts, onError }) => {
  const { t } = useTranslation();
  const { jisaDependants = [], mutate: mutateJisaDependants } = useGetCustomerDependantPersonalInformation(userUuid, {
    onError,
  });
  const timeoutRef = useRef(null);

  const handleRemoveJisaAccount = async (childCustomerId) => {
    const newJisaDependants = jisaDependants.filter(({ customerId }) => customerId !== childCustomerId);

    await mutateJisaDependants({ jisaDependants: newJisaDependants });

    // Due to the delay on the service side set a timeout to update drafts after 0.5 s
    timeoutRef.current = setTimeout(() => {
      updateDrafts();
    }, 500);
  };
  const wrapperTypes = Object.keys(drafts);
  const allDrafts = wrapperTypes.reduce((allDrafts, wrapperType) => {
    if (drafts[wrapperType].data.length) {
      return [...allDrafts, ...drafts[wrapperType].data];
    }

    return allDrafts;
  }, []);

  useEffect(() => {
    // Clear the timeout if the component unmounts before the timeout completes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (!allDrafts.length) return null;

  return (
    <>
      <Heading level="3" data-qa="product-list__drafts-title">
        {t(`${TRANSLATION_NAMESPACE}.heading`)}
      </Heading>
      <DraftsList
        customerUuid={userUuid}
        drafts={allDrafts}
        updateDrafts={updateDrafts}
        jisaDependants={jisaDependants}
        isJisaDuplicationEnabled
        onRemoveJisaAccount={handleRemoveJisaAccount}
      />
    </>
  );
};

DraftPots.propTypes = {
  drafts: arrayOf(object).isRequired,
  userUuid: string.isRequired,
  updateDrafts: func.isRequired,
  onError: func,
};

DraftPots.defaultProps = {
  onError: () => {},
};

export default DraftPots;
