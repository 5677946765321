import React, { useEffect, useMemo, useRef } from 'react';
import { PotsOverview } from '@nm-pot/overview';
import { arrayOf, func, object, string, bool } from 'prop-types';
import { Heading, headingLevels } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';
import { POT_STATUSES } from '@nm-pot/common/constants';
import Inline, { inlineSpaces } from '@nutkit/component-inline';
import { useGetCustomerDependantPersonalInformation } from '@nm-customer/common/hooks';
import Loading from '@nutkit/component-loading';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const JISATab = ({ pots, drafts, updateDrafts, onError, userUuid, withHeading }) => {
  const { t } = useTranslation();
  const {
    jisaDependants,
    isLoading: isJisaDependantsLoading,
    mutate: mutateJisaDependants,
  } = useGetCustomerDependantPersonalInformation(userUuid, { onError });
  const potsToDisplay = useMemo(
    () => pots.filter(({ status }) => [POT_STATUSES.ACTIVE, POT_STATUSES.CLOSING].includes(status)),
    [pots],
  );
  const hasJisaDependants = jisaDependants && jisaDependants.length > 0;
  const hasNoPotsToShow = !drafts.length && !potsToDisplay.length;
  const timeoutRef = useRef(null);

  const handleRemoveJisaAccount = async (childCustomerId) => {
    const newJisaDependants = jisaDependants.filter(({ customerId }) => customerId !== childCustomerId);

    await mutateJisaDependants({ jisaDependants: newJisaDependants });

    // Due to the delay on the service side set a timeout to update drafts after 0.5 s
    timeoutRef.current = setTimeout(() => {
      updateDrafts();
    }, 500);
  };

  useEffect(() => {
    // Clear the timeout if the component unmounts before the timeout completes
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (isJisaDependantsLoading) {
    return <Loading />;
  }

  if (!hasJisaDependants || hasNoPotsToShow) {
    return null;
  }

  return (
    <>
      {withHeading && (
        <Heading level={headingLevels.THREE} data-qa="product-list__jisa-title">
          <Inline space={inlineSpaces.SM}>{t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.jisa.label`)}</Inline>
        </Heading>
      )}
      <PotsOverview
        customerUuid={userUuid}
        pots={potsToDisplay}
        drafts={drafts}
        isJisaDuplicationEnabled
        jisaDependants={jisaDependants}
        onRemoveJisaAccount={handleRemoveJisaAccount}
        onError={onError}
      />
    </>
  );
};

JISATab.propTypes = {
  pots: arrayOf(object).isRequired,
  drafts: arrayOf(object),
  onError: func,
  userUuid: string.isRequired,
  withHeading: bool,
  updateDrafts: func,
};

JISATab.defaultProps = {
  drafts: [],
  withHeading: false,
  onError: undefined,
  updateDrafts: undefined,
};

export default JISATab;
