import React from 'react';
import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { getProductByWrapper } from '@nm-pot/common/helpers';
import { Heading, headingLevels } from '@nutkit/component-text';
import { useTranslation } from '@nm-utils-lib-web/translations';

import SISATab from '../SISATab';
import LISATab from '../LISATab';
import PensionTab from '../PensionTab';
import JISATab from '../JISATab';

const NAVIGATION_TABS_TRANSLATION_NAMESPACE = 'dashboard.portfolioDashboard.productList.navigationTabs';

const GrowthTab = ({ pots, ...props }) => {
  const { t } = useTranslation();
  const sisa = getProductByWrapper(WRAPPER_TYPES.GIA_ISA, pots);
  const lisa = getProductByWrapper(WRAPPER_TYPES.LISA, pots);
  const pension = getProductByWrapper(WRAPPER_TYPES.PENSION, pots);
  const jisa = getProductByWrapper(WRAPPER_TYPES.JISA, pots);

  return (
    <>
      <Heading level={headingLevels.THREE} data-qa="product-list__growth-title">
        {t(`${NAVIGATION_TABS_TRANSLATION_NAMESPACE}.GROWTH.label`)}
      </Heading>
      <SISATab {...props} pots={sisa} />
      <LISATab {...props} pots={lisa} />
      <PensionTab {...props} pots={pension} />
      <JISATab {...props} pots={jisa} />
    </>
  );
};

GrowthTab.propTypes = {
  ...SISATab.propTypes,
  ...LISATab.propTypes,
  ...PensionTab.propTypes,
  ...JISATab.propTypes,
};

GrowthTab.defaultProps = {
  ...SISATab.defaultProps,
  ...LISATab.defaultProps,
  ...PensionTab.defaultProps,
  ...JISATab.defaultProps,
};

export default GrowthTab;
