import { WRAPPER_TYPES } from '@nm-pot/common/constants';
import { getProductByWrapper } from '@nm-pot/common/helpers';

const hasValidWrapperIntent = ({ wrapperIntent }) => wrapperIntent && wrapperIntent.key !== WRAPPER_TYPES.GIA_ISA;

const getProductByWrapperIntent = (wrapper, data = []) => {
  return data
    .filter(({ wrapperIntent: { key: wrapperKey } }) => wrapperKey.toUpperCase() === wrapper.toUpperCase())
    .map((draft) => ({ ...draft, productType: draft.wrapperIntent.key }));
};

export const getAvailableDraftsByWrapper = (drafts) => {
  if (!drafts) {
    return undefined;
  }

  const lisa = getProductByWrapper(WRAPPER_TYPES.LISA, drafts).map((draft) => ({
    ...draft,
    productType: draft.wrapper.key,
  }));
  const draftsWithWrapperIntent = drafts.filter(hasValidWrapperIntent);
  const sisa = getProductByWrapperIntent(WRAPPER_TYPES.SISA, draftsWithWrapperIntent);
  const gia = getProductByWrapperIntent(WRAPPER_TYPES.GIA, draftsWithWrapperIntent);
  const jisa = getProductByWrapperIntent(WRAPPER_TYPES.JISA, draftsWithWrapperIntent);

  return {
    [WRAPPER_TYPES.SISA]: { data: [...sisa, ...gia], isPresent: sisa.length > 0 || gia.length > 0 },
    [WRAPPER_TYPES.LISA]: { data: lisa, isPresent: lisa.length > 0 },
    [WRAPPER_TYPES.JISA]: { data: jisa, isPresent: jisa.length > 0 },
  };
};
